<template>
  <div class="iq-sidebar" style="height: 100%;">
    <div id="sidebar-scrollbar">
      <nav class="iq-sidebar-menu">
        <List :items="items" :open="true" :horizontal="horizontal"/>
      </nav>
    </div>
  </div>
</template>
<style>
  #sidebar-scrollbar{
    padding-bottom: 100px;
  }
</style>
<script>
import List from "../menus/ListStyle1.vue"
export default {
  name: "SideBarStyle1",
  props: {
    homeURL: { type: Object, default: () => ({ name: "layout1.dashboard" }) },
    items: { type: Array },
    logo: { type: String, default: require("../../../assets/images/logo.png") },
    horizontal: { type: Boolean },
    toggleButton: { type: Boolean, default: true }
  },
  components: {
    List
  },
  methods: {
    miniSidebar () {
      this.$emit("toggle")
    }
  },
  data () {
    return {
    }
  }
}
</script>
