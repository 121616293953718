/*eslint-disable */
// import AdminNotificationModel from '../../Model/AdminNotification'
import request from '../../Utils/curl'
import Utility from "../../Utils/utility";

let AdminNotifications = {
  /**
   * adminNotificationList
   */
  async adminNotificationList (context, whereFilter=null) {
    try{
      Utility.showLoader = true;
      Utility.loadingMsg = "Please wait....";

      let post_data = new FormData();  
      if (whereFilter) {
        post_data.append('filter', JSON.stringify(whereFilter))
      }

      return await request.curl(context, "admin_notification_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at adminNotificationList() and Exception:',err.message)
    }
    finally{
      Utility.showLoader = false;
    }
  },

  /**
   * adminNotificationView
   */
  async adminNotificationView (context, adminNotificationId) {
    try {      
      let post_data = new FormData();
      post_data.append('notification_id', adminNotificationId);
      return await request.curl(context, "admin_notification_view", post_data)
      .then(async response => {
        return response;
      });
    } 
    catch (err) {
      console.error("Exception occurred at adminNotificationView() and Exception:",err.message)
    }    
  },

  /**
   * adminNotificationAdd
   */
  async adminNotificationAdd (context, adminNotificationObj) {
    try{
      let post_data = new FormData();
    
      for (let key in adminNotificationObj) {
        post_data.append(key, adminNotificationObj[key]);
      }

      return await request.curl(context, "admin_notification_add", post_data)
      .then(async response => {
          return response;
      });
    }
    catch(err){
      console.error('Exception occurred at adminNotificationAdd() and Exception:',err.message)
    }
  },

  /**
   * adminNotificationEdit
   */
  async adminNotificationEdit (context, adminNotificationObj) {
    try{
      let post_data = new FormData();
    
      for (let key in adminNotificationObj) {
        post_data.append(key, adminNotificationObj[key]);
      }

      return await request.curl(context, "admin_notification_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at adminNotificationEdit() and Exception:',err.message)
    }
  },

  /**
   * adminNotificationDelete
   */
  async adminNotificationDelete (context, adminNotificationId) {
    try{
      let post_data = new FormData();
      
      post_data.append('notification_id', adminNotificationId);

      return await request.curl(context, "admin_notification_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at adminNotificationDelete() and Exception:',err.message)
    }
  },

  /**
   * adminNotificationPublishUnpublish
   */
  async adminNotificationPublishUnpublish (context, Noti) {
    try{
      let post_data = new FormData();
      post_data.append('notification_id', Noti.notification_id);
      post_data.append('deleted',Noti.deleted);

      return await request.curl(context, "admin_notification_publish_unpublish", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at admin_notification_publish_unpublish() and Exception:',err.message)
    }
  },
}

export {
  AdminNotifications
}
