<template>
  <span v-if="userData && userData.user_is_logged_in" style="pointer-events:none;" class="logoSpan">
    <img v-if="getUserProfilePic()" :class="propSize ? propSize : 'avatar-60'"  :src="getUserProfilePic()" class="img-fluid rounded-circle mr-3" alt="user">
    <b-avatar v-else class="mr-3" :class="propSize ? propSize : 'avatar-60'" :variant="getBgColorForLetter(userData.user_name)" :text="getFirstLetterOfAString(userData.user_name)" ></b-avatar>
  </span>
</template>

<script>
import Utility from "../../../Utils/utility"
export default {
  name: "UserLogo",
  props: [ "propSize", "propPostUserData" ],
  computed: {
    userData () {
      let userData = Object.assign({}, this.$store.getters["User/userData"])
      if (this.propPostUserData && Object.keys(this.propPostUserData).length > 0) {
        userData.user_name = this.propPostUserData.user_name
        userData.user_image = this.propPostUserData.user_image
      }
      return userData
    }
  },
  data () {
    return {
      cvUserProfilePic: ""
    }
  },
  methods: {
    /**
     * getUserProfilePic
     */
    getUserProfilePic () {
      if (this.userData && this.userData.user_image && Object.keys(this.userData).length > 0) {
        return this.userData.user_image.profile_pic && this.userData.user_image.profile_pic.image_thumbnail
      }
      else {
        return false
      }
    },
    /**
     * getFirstLetterOfAString
     */
    getFirstLetterOfAString (string) {
      if (string) {
        return string.substr(0, 1)
      }
    },
    /**
     * getBgColorForLetter
     */
    getBgColorForLetter (name) {
      let firstLetter = this.getFirstLetterOfAString(name)
      return Utility.getBgColorForLetter(this, firstLetter)
    }
  }
}
</script>

<style scoped>
  .logo-icon{
    height: 50px;
    width: 50px;
    overflow: hidden;
    object-fit: cover;
    pointer-events: none;
    border-radius: 50%;
  }
</style>
