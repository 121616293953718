/*eslint-disable */
// import LikeModel from '../../Model/Like'
import request from '../../Utils/curl'

let MeshApi = {
  /**
   * MeshGroupList
   */
  async MeshGroupList (context, postData) {
    try{
      let post_data = new FormData();
  
      for (let key in postData) {
        post_data.append(key, postData[key]);
      }

      return await request.curl(context, "group_list", post_data, process.env.VUE_APP_MESH_API)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at MeshGroupList() and Exception:',err.message)
    }
  },
  async MeshUserContactList (context, postData) {
    try{
      let post_data = new FormData();

      for (let key in postData) {
        post_data.append(key, postData[key]);
      }

      return await request.curl(context, "user_contact_list", post_data, process.env.VUE_APP_MESH_API)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at MeshUserContactList() and Exception:',err.message)
    }
  },
  async MeshGroupEdit (context, postData) {
    try{
      let post_data = new FormData();

      for (let key in postData) {
        post_data.append(key, postData[key]);
      }

      return await request.curl(context, "group_edit", post_data, process.env.VUE_APP_MESH_API)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at MeshGroupEdit() and Exception:',err.message)
    }
  },
  async MeshGroupDelete (context, postData) {
    try{
      let post_data = new FormData();

      for (let key in postData) {
        post_data.append(key, postData[key]);
      }

      return await request.curl(context, "group_delete", post_data, process.env.VUE_APP_MESH_API)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at MeshGroupDelete() and Exception:',err.message)
    }
  },
  /**
   * MeshChatInviteSend
   */
  async MeshChatInviteSend (context, meshGrpAddObj) {
    try{
      let post_data = new FormData();
      for (let key in meshGrpAddObj) {
        if ((meshGrpAddObj[key] && (typeof meshGrpAddObj[key] !== 'number') || typeof meshGrpAddObj[key] === 'number')) {
          post_data.append(key, meshGrpAddObj[key]);
        }
      }
      return await request.curl(context, "group_add", post_data, process.env.VUE_APP_MESH_API)
        .then(async response => {
          return response
        });
    }
    catch(err){
      console.error('Exception occurred at MeshChatInviteSend() and Exception',err.message)
    }
  }
}
export {
  MeshApi
}
